import ApexCharts from 'apexcharts';
$(document).on('turbolinks:load', function () {
  var super_group_labels = $('#super_groups').data('names');
  var super_group_values = $('#super_groups').data('values');
  var super_group_colors = $('#super_groups').data('colors');
  var options = {
    chart: {
      height: 230,
      type: 'donut',
      dropShadow: {
        enabled: !0,
        top: 10,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: '#45404a2e',
        opacity: 0.15,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85%',
        },
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ['transparent'],
    },
    series: super_group_values,
    legend: {
      show: !1,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: !1,
      fontSize: '14px',
      offsetX: 0,
      offsetY: -13,
    },
    labels: super_group_labels,
    colors: super_group_colors,
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            donut: {
              customScale: 0.2,
            },
          },
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (e) {
          return e + ' %';
        },
      },
    },
  };
  if (document.querySelector('#ana_device')) {
    var chart = new ApexCharts(document.querySelector('#ana_device'), options);
    chart.render();
  }

  var times = $('#hourly_sales').data('times');
  var hourly_sales_values = $('#hourly_sales').data('values');
  var bar_colors = [];
  if (hourly_sales_values && hourly_sales_values.length > 0) {
    bar_colors = hourly_sales_values.map((group) => group.color);
  }
  var options = {
    series: hourly_sales_values,
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          console.log(config);
          // gets the position of the marker in the series
          const dp = config.dataPointIndex;

          // use the series position of the clicked marker to find the corresponding x axis data at that point in the series
          let date = chartContext.data.twoDSeriesX[dp];
          date = moment(date).format('MM/DD/YYYY');
          console.log(date);
          $.get(
            '/reports/super_group_top/' + config.seriesIndex + '?date=' + date,
            (data) => {
              $('.remote_content').html(data);
              $('#top5').modal('show');
            }
          );
          // ...
        },
      },
    },
    colors: bar_colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '10px',
      },
      formatter: function (val, opt) {
        return '$' + val.toLocaleString();
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      // type: 'datetime',
      categories: times,
      labels: {
        formatter: function (value) {
          const parsedMoment = moment.parseZone(value);
          // Get the time zone offset correctly from the parsed moment
          const timeZoneOffset = parsedMoment.format('Z');
          return parsedMoment.format('hh:mm a'); // Format as '2-digit hour:2-digit minute'
        },
        style: {
          fontSize: '10px',
        },
      },      
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return '$' + value.toLocaleString();
        },
      },
    },
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };
  if (document.querySelector('#sales_chart')) {
    var chart = new ApexCharts(document.querySelector('#sales_chart'), options);
    chart.render();
  }
});
